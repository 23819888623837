.ant-picker-cell {
    width: 20px !important;
}

.ant-picker-cell-hover, .ant-picker-cell-selected {
    background-color: #006D38;
    border-radius: 20%;
    color: white !important;
}

.ant-picker-cell-hover div p, .ant-picker-cell-selected div p {
    color: #fff;
}