.note-detail {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.note:hover .note-detail {
    opacity: 1;
}
.chi-tiet {
    max-width: 70%;
}
@media only screen and (max-width: 500px) {
    .chi-tiet {
        max-width: 70%;
    }
}