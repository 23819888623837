@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
  font-family: 'Quicksand';
}

.ant-btn {
  background-color: #006D38;
  border-radius: 6px !important;
  color: #fff;
}

.ant-btn:hover {
  background-color: #fff;
}
.pause-btn {
  color: #fff;
  background-color: #2D80E9;
}

.route-template-btn {
  background-color: #7645FF;
}

.ant-input, .ant-select-selector, .ant-input-affix-wrapper, .ant-picker {
  border-radius: 4px !important;
}

.office-btn {
  background-color: #80B155;
}

.template-btn {
  background-color: #1d91d8;
}

.route-btn {
  background-color: #498428;
}

.policy-btn {
  background-color: #7645FF;
}

.save-btn {
  background-color: #80B155;
  color: #fff;
}

.loading-indicator:before {
  content: '';
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: '';
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color:white;
  text-align:center;
  font-weight:bold;
  font-size: 20px;        
}

.ant-switch {
  background-color: #808080;
}