.ant-select-disabled .ant-select-selector {
    background: white !important;
    color: black !important;
}

.ant-input-disabled, .ant-picker-disabled {
    background: white !important;
    color: black !important;
}

.ant-picker-disabled .ant-picker-input input {
    color: black !important;
}

.ant-select-selection-item {
    color: black !important;
}