.li-schedule {
    height: 36px;
    background-color: #EAEAEA;
    margin-bottom: 16px;
}
.un-choose-btn {
    background-color: white;
    color: black;
}

.off-timeslot {
    background-color: white;
    color: red;
}

.ant-progress-text {
    visibility: hidden;
}
.active {
    background-color: #006D38;
    color: white;
    border-color: green;
}